import React, { Fragment, useState, useEffect, useRef } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row, Table } from "reactstrap";
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import Typeahead from "../../../CommonElements/TypeAhead";
import {
  AdduserAgent, ChooseCompany, City, ConfirmPassword, Country, Email, FirstName, Language, LastName, Locale, MobileNo, Password,
  Role, Save, State, Status, UserAgent, zipcode
} from "../../../Constant";
import { CompanySubView, Postagent, getLanguage, getRole, } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import { getClientUrlDataToken, getMasterDataToken, postClientUrlWithToken } from "../../../Utils/restUtils";
import Areafield from "../../Common/Area Field/Areafield";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Transparentspinner from "../../Common/Loader/Transparentspinner";
import HandleApiError from "../../Common/ErrorImport/ErrorImport";

const schema = yup.object().shape({
  firstname: yup.string().required().max(14, 'First Name must be at most 14 characters').label('First Name'),
  lastname: yup.string().required().max(14, 'Last Name must be at most 14 characters').label('Last Name'),
  email: yup.string().email('Invalid email format').required('Email is required'),
  role: yup.mixed().required().label('Role'),
  language: yup.mixed().required().label('Language'),
  phoneNumber: yup
    .string()
    .required("Phone number is required.")
    .min(10, "Minimum 10 digits required.")
    .max(15, "Maximum 15 digits allowed.")
    .matches(/^\d+$/, "Phone number must contain only digits.")
    .label("Phone number"),
  status: yup.mixed().required().label('Status'),
  password: yup.string().required('Password id required').min(8, 'Password must be at least 8 characters long'),
  password_repeat: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  rows: yup.array().of(
    yup.object().shape({
      companyName: yup.object().nullable().shape({
        value: yup.string().required("Company is required"), // Check that 'value' inside companyName is required
        label: yup.string(),
      }).required("Company is required")
      .typeError("Company is required"),
      area: yup.array().of(yup.number()).min(1,"Area is required").required("Area is required").typeError("Area is required") // Ensure area is an array and has at least one value
    })
  ),
})

const UserAgentForm = () => {
  const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      rows: [
        {
          companyName: null, 
          area: [],
        },
      ],
    },
  });
  console.log(errors);
   
  const { fields, append, remove } = useFieldArray({ control, name: 'rows' });
  const companyNameRef = useRef(null);
  const areaRef = useRef(null);
  const authState = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([])
  const [language, setlanguage] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const navigate = useNavigate();
  const [togglePassword, setTogglePassword] = useState(false);
  const [toggleConPassword, setToggleConPassword] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState({});
  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
    { value: "suspended", label: "Suspended" },
    { value: "blocked", label: "Blocked" },
  ];

  useEffect(() => {
    companySubData();
    RoleData()
  }, []);

  useEffect(() => {
    fields.forEach((item, index) => {
      if (errors?.rows?.[index]?.companyName) {
        companyNameRef.current?.querySelector('input')?.focus();
      } else if (errors?.rows?.[index]?.area) {
        areaRef.current?.querySelector('input')?.focus();
      }
    });
  }, [errors, fields]);

  useEffect(() => {
    if (fields.length === 0) {
      append({ companyName: '', area: '' }); // Default values for the first row
    }
  }, [append, fields.length]);

  const handleAddRow = () => {
    append({ companyName: '', area: '' }); // Add default values as necessary
  };

  const companySubData = async () => {
    setLoading(true);
    const company_api = await getMasterDataToken(CompanySubView);
    if (company_api.status === true) {
      const companyList = company_api.data
        .filter((item) => item.subscription === 'yes')
        .map((item) => ({
          value: item.company_id,
          label: item.comapny_name,
        }));
      setCompanyList(companyList);
    } else {
      HandleApiError(company_api.status);
    }
    setLoading(false);
  };

  const handleCompanyChange = (index, selected) => {
    const selectedCompanyId = selected?.value || null;
    setSelectedCompanies((prevSelected) => {
      const newSelected = { ...prevSelected, [index]: selectedCompanyId };
      if (!selectedCompanyId) delete newSelected[index]; // Remove if deselected
      return newSelected;
    });
  };

  const RoleData = async () => {
    const data = await getClientUrlDataToken(authState.apiUrl, getRole);
    if (data.status === true) {
      const mappedrole = data.data.map((items) => ({
        value: items.id,
        label: items.name
      }))
      setRoles(mappedrole);
    } else {
      HandleApiError(data.status);
    }

    const lang_api = await getClientUrlDataToken(authState.apiUrl, getLanguage);
    if (data.status === true) {
      const reponse = lang_api.data;
      const mappedLang = Object.keys(reponse).map((key) => ({ label: reponse[key], value: key }));
      setlanguage(mappedLang);
    } else {
      HandleApiError(data.status);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const countryCode = data.countryCode;
    const phoneNumber = data.phoneNumber;
    const companyData = data.rows.map(row => ({
      Company: row.companyName.value, // Extracting company ID
      Area: row.area, // List of area IDs for this company
    }));

    if (phoneNumber.startsWith(countryCode)) {
      const b = phoneNumber.slice(countryCode.length);
      const postRequest = {
        first_name: data.firstname,
        last_name: data.lastname,
        mobile: b,
        email: data.email,
        role_id: data.role.value,
        country_code: data.countryCode,
        password: data.password,
        confirm_password: data.password_repeat,
        latitude: "4567886",
        longitude: "98876756",
        city: data.city,
        state: data.state,
        country: data.country,
        zip_code: data.zipcode,
        locale: data.language.value,
        status: data.status.value,
        company_data: companyData,
      };
      const addCustomerResponse = await postClientUrlWithToken(
        authState.apiUrl,
        Postagent,
        postRequest
      );
      if (addCustomerResponse.status === 200) {
        if (addCustomerResponse.data.status === true) {
          navigate(`${process.env.PUBLIC_URL}/menu/user`);
        } else {
          addCustomerResponse.data.message.forEach((err) => {
            toast.error(err);
          });
          setLoading(false);
        }
      } else {
        HandleApiError(addCustomerResponse.status);
      }
      setLoading(false);
    };
  };

  return (
    <Fragment>
      {loading && <Transparentspinner />}
      <Breadcrumbs mainTitle={UserAgent} parent="Customers" title={UserAgent} />
      <Card>
        <CardHeader className="pb-0"><H3>{AdduserAgent}</H3></CardHeader>
        <CardBody>
          <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label">{FirstName} <span className="requireStar">*</span></Label>
                <input className="form-control" type="text" {...register("firstname")} />
                <p className="text-danger">{errors.firstname?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" >{LastName} <span className="requireStar">*</span></Label>
                <input className="form-control" type="text" {...register("lastname")} />
                <p className="text-danger">{errors.lastname?.message}</p>
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label"> {MobileNo} <span className="requireStar">*</span></Label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      country={"in"}
                      enableSearch={true}
                      onChange={(value, country) => {
                        setValue("countryCode", country.dialCode);
                        field.onChange(value);
                      }}
                      inputStyle={{
                        width: "100%",
                        height: "46px",
                        padding: "10px",
                        paddingLeft: "50px",
                        fontSize: "16px",
                        borderRadius: "8px",
                        borderColor: "rgba(83, 70, 134, 0.1019607843)",
                      }}
                      searchClass="none"
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.phoneNumber && errors.phoneNumber.message}
                </span>
              </Col>
              <Col md="6">
                <Label className="form-label"> {Email} <span className="requireStar">*</span></Label>
                <input className="form-control" type="email" {...register("email", { required: true })} />
                <p className="text-danger">{errors.email?.message}</p>
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" >{Role} <span className="requireStar">*</span></Label>
                <Controller
                  name="role"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      options={roles}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.role?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label">{Language} <span className="requireStar">*</span></Label>
                <Controller
                  name="language"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      options={language}
                      onChange={(value) => {
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.language?.message}</p>
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label">{City}</Label>
                <input className="form-control" type="text" {...register("city", { required: true })} />
                <p className="text-danger">{errors.city?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label">{State}</Label>
                <input className="form-control" type="text" {...register("state", { required: true })} />
                <p className="text-danger">{errors.state?.message}</p>
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label">{Country}</Label>
                <input className="form-control" type="text" {...register("country", { required: true })} />
                <p className="text-danger">{errors.country?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label">{zipcode}</Label>
                <input className="form-control" type="text" {...register("zipcode", { required: true })} />
                <p className="text-danger">{errors.zipcode?.message}</p>
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" >{Locale}</Label>
                <input className="form-control" type="text" {...register("local", { required: true })} />
                <p className="text-danger">{errors.local?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" >{Status} <span className="requireStar">*</span></Label>
                <Controller
                  name="status"
                  control={control} // Set the initial value to null
                  render={({ field }) => (
                    <Typeahead
                      options={statusOptions}
                      onChange={(value) => {
                        field.onChange(value); // Update the value in react-hook-form
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.type?.message}</p>
              </Col>
            </Row>

            <div style={{ paddingTop: '20px' }}>
              <H3>{ChooseCompany}</H3>
            </div>
            <div className="bg-subscription">
              <Row>
                <div className="table-responsive-set">
                  <Table className="table border subcription-table mt-4">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Area</th>
                        <th className="d-flex justify-content-between align-items-center">
                          Action
                          <button
                            type="button"
                            onClick={handleAddRow}
                            className="btn btn-primary btn-sm ml-auto"
                          >
                            +
                          </button>
                        </th>
                      </tr>  
                    </thead>
                    <tbody>
                      {fields.map((item, index) => (
                        <tr key={item.id}>
                          <td className="col-sm-12 col-md-5">
                            <label>Name</label>
                            <Controller
                              name={`rows[${index}].companyName`}
                              control={control}
                              render={({ field }) => {
                                const filteredOptions = companyList.filter(
                                  (company) =>
                                    !Object.values(selectedCompanies).includes(company.value) ||
                                    selectedCompanies[index] === company.value
                                );
                                return (
                                  <div>
                                    <Typeahead
                                      id={`company-select-${index}`}
                                      options={filteredOptions}
                                      ref={companyNameRef}
                                      onChange={(selected) => {
                                        field.onChange(selected); 
                                        handleCompanyChange(index, selected);
                                      }}
                                      value={field.value || null}
                                      placeholder="Select company"    
                                      isDisabled={false}
                                    />
                                    {errors?.rows?.[index]?.companyName && (
                                      <p className="text-danger">{errors.rows[index].companyName?.message}</p>
                                    )}
                                  </div>
                                );
                              }}
                            />
                          </td>
                          <td className=" col-sm-12 col-md-5">
                            <label>Area</label>
                            <Areafield
                              name={`rows[${index}].area`}
                              index={index} selectedCompany={selectedCompanies[index]}
                              control={control}
                              errors={errors}
                              ref={areaRef}
                            />
                          </td>
                          <td className="">
                            <button type="button"
                              onClick={() => {
                                remove(index);
                                handleCompanyChange(index, []);
                              }}
                              className="btn btn-danger">
                              <i className="fa fa-trash-o"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Row>
            </div>

            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label">{Password} <span className="requireStar">*</span></Label>
                <div className='form-input position-relative'>
                  <input className="form-control" type={togglePassword ? 'text' : 'password'}
                    {...register("password", { required: true })}
                  />
                  <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                    <span className={togglePassword ? '' : 'show'}></span>
                  </div>
                </div>
                <p className="text-danger">{errors.password?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" >{ConfirmPassword} <span className="requireStar">*</span></Label>
                <div className='form-input position-relative'>
                  <input className="form-control" type={toggleConPassword ? 'text' : 'password'}
                    {...register("password_repeat", { required: true })}
                  />
                  <div className="show-hide" onClick={() => setToggleConPassword(!toggleConPassword)}>
                    <span className={toggleConPassword ? '' : 'show'}></span>
                  </div>
                </div>
                <p className="text-danger">{errors.password_repeat?.message}</p>
              </Col>
            </Row>
            <FormGroup></FormGroup>
            <Btn attrBtn={{ color: "primary" }} type="submit">
              {Save}
            </Btn>
          </Form>
        </CardBody>
      </Card>
    </Fragment>
  );
};
export default UserAgentForm;
