import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Col, Form, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Breadcrumbs, Btn, H4 } from '../../AbstractElements';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DatePicker from 'react-datepicker';
import { getClientUrlDataToken } from '../../Utils/restUtils';
import { Export, Search, Filter, Account, PaymentCollectionRep, PaymentCollectionList } from '../../Constant';
import { getAccount, getCustomerPaymentReport, getCustomerPaymentReportRole, getAccountRole, getCollectionReportRole, getCollectionReport } from '../../CommonUrl';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Transparentspinner from '../Common/Loader/Transparentspinner';
import { EarningsSvg } from '../../Data/Widgets/General/Data';
import Progress from '../Common/Progress';
import { useForm } from 'react-hook-form';
import UserDropdown from '../Common Field/UserDropdown';
import AreaDropdown from '../Common Field/AreaDropdown';


const CustomerPaymentReport = () => {
    const authState = useSelector((state) => state.auth);
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const [filters, setFilters] = useState({
        searchKey: params.get('search') || '',
        start_date: params.get('start_date') ? moment(params.get('start_date'), "DD-MM-YYYY").toDate() : '',
        end_date: params.get('end_date') ? moment(params.get('end_date'), "DD-MM-YYYY").toDate() : '',
        areaFilter: params.get('area') || '',
        accountId: params.get('account') || '',
        userId: params.get("user") || ''
    });
    const param1 = params.get("day") || '';
    const sortedBy = params.get("sortBy") || '';
    const sortedOrder = params.get("sortOrder") || '';
    const [customerLists, setCustomerList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [dateRange, setDateRange] = useState([filters.start_date, filters.end_date]);
    const [startDate, endDate] = dateRange
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const [apiAccount, setapiAccount] = useState([]);
    const [totalsum, settotalsum] = useState("0");
    const formatStartDate = filters.start_date ? moment(filters.start_date).format("DD-MM-YYYY") : '';
    const formatEndDate = filters.end_date ? moment(filters.end_date).format("DD-MM-YYYY") : '';
    const [sortConfig, setSortConfig] = useState({ column: sortedBy, order: sortedOrder });
    const { register, handleSubmit, setValue } = useForm({
        defaultValues: { search: filters.searchKey, area: filters.areaFilter, account: filters.accountId, user: filters.userId },
    });

    const StaticWidgetsData = [
        {
            id: 1,
            title: 'Paid',
            counter: totalsum,
            svg: <EarningsSvg />,
            class: 'progress-gradient-secondary',
            progress: '75%'
        },
    ];

    const PaymentCollectionColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            center: false,
            width: '80px'
        },
        {
            name: <H4>Subscriber Id</H4>,
            id: 'subscriber_id',
            selector: row =>
                (authState.userRole === 1 || authState.permissions.includes('show-customer-profile')) ?
                    <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.customer_id}`}>
                        {row.Sub}
                    </Link> : row.Sub,
            sortable: true,
            wrap: true,
            width: '105px',
        },
        {
            name: <H4>Invoice No.</H4>,
            id: 'invoice_no',
            selector: row =>
                <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile/invoice?invoice_id=${row.id}&customer_id=${row.customer_id}`}>
                    {row.InvoiceNo}
                </Link>,
            sortable: true,
            wrap: true,
            center: false,
        },
        {
            name: <H4>Txn Id</H4>,
            id: 'transaction_id',
            selector: row => row.txnDate,
            sortable: true,
            center: false,
            wrap: true
        },
        {
            name: <H4>Name</H4>,
            id: 'name',
            selector: row => row.Name,
            sortable: true,
            center: false,
            wrap: true
        },
        {
            name: <H4>Address</H4>,
            id: 'address',
            selector: row => row.Address,
            sortable: true,
            center: false,
            wrap: true
        },
        {
            name: <H4>Area</H4>,
            id: 'area_name',
            selector: row => row.Area,
            sortable: true,
            center: false,
            wrap: true
        },
        {
            name: <H4>Amount Collected</H4>,
            id: 'collected_amount',
            selector: row => row.AmountCollected,
            sortable: true,
            center: false,
            wrap: true

        },
        {
            name: <H4>Paid Date</H4>,
            id: 'paid_date',
            selector: row => row.PaidDate,
            sortable: true,
            center: false,
            wrap: true
        },
        {
            name: <H4>Collected By</H4>,
            id: 'collected_by',
            selector: row => row.CollectedBy,
            sortable: true,
            center: false,
            wrap: true
        },
        {
            name: <H4>Account</H4>,
            id: 'account_name',
            selector: row => row.Account,
            sortable: true,
            center: false,
            wrap: true,
        },
    ];

    useEffect(() => {
        fetchCustomerPayment(1);
        accountData();
    }, []);

    useEffect(() => {
        setValue('search', filters.searchKey);
    }, [setValue, filters.search]);

    useEffect(() => {
        if (filters.accountId) {
            const selectedAccount = apiAccount.find((account) => account.id === parseInt(filters.accountId));
            if (selectedAccount) {
                setValue('account', selectedAccount.id.toString());
            }
        }
    }, [setValue, filters.accountId, apiAccount]);

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleSort = (column) => {
        let newOrder = 'asc';
        if (sortedBy === column.id) {
            newOrder = sortedOrder === 'asc' ? 'desc' : 'asc';
            setSortConfig({ column: column.id, order: newOrder });
            navigate(`/report/payment-collection?search=${filters.searchKey}&start_date=${formatStartDate}&end_date=${formatEndDate}&area=${filters.areaFilter}&account=${filters.accountId}&user=${filters.userId}&sortBy=${column.id}&sortOrder=${newOrder}`);
        } else {
            newOrder = sortedOrder === 'asc' ? 'desc' : 'asc';
            setSortConfig({ column: column.id, order: newOrder });
            navigate(`/report/payment-collection?search=${filters.searchKey}&start_date=${formatStartDate}&end_date=${formatEndDate}&area=${filters.areaFilter}&account=${filters.accountId}&user=${filters.userId}&sortBy=${column.id}&sortOrder=${newOrder}`);
        }
    };

    const handleApiError = (status) => {
        if (status >= 400 && status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (status >= 500 && status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const onSubmit = async (data) => {
        const searchKey = data.search || '';
        const start_date = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end_date = endDate ? moment(endDate).format("DD-MM-YYYY") : '';
        const areaFilter = data.area || '';
        const accountId = data.account || '';
        const userId = data.user || '';
        const queryParams = new URLSearchParams({
            search: searchKey,
            start_date,
            end_date,
            area: areaFilter,
            account: accountId,
            user: userId,
            sortBy: sortConfig.column || '',
            sortOrder: sortConfig.order || '',
        }).toString();
        navigate(`/report/payment-collection?${queryParams}`);
        setFilters({
            searchKey,
            start_date,
            end_date,
            areaFilter,
            accountId,
            userId
        });
        fetchCustomerPayment(currentPage, perPage);
    };

    const fetchCustomerPayment = async (page, size = perPage) => {
        setLoading(true);
        setCustomerList([]);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1
            ? getCollectionReport + `?page=${page}&page_length=${size}&search=${filters.searchKey}&area_id=${filters.areaFilter}
            &start_date=${formatStartDate}&end_date=${formatEndDate}&account_id=${filters.accountId}
            &user_id=${filters.userId}&day=${param1}&sort_by=${sortConfig.column ? sortConfig.column : ''}
            &sort_order=${sortConfig.order ? sortConfig.order : ''}`
            : getCollectionReportRole + `?page=${page}&page_length=${size}&search=${filters.searchKey}
            &area_id=${filters.areaFilter}&start_date=${formatStartDate}&end_date=${formatEndDate}
            &account_id=${filters.accountId}&user_id=${filters.userId}&sort_by=${sortConfig.column ? sortConfig.column : ''}
            &sort_order=${sortConfig.order ? sortConfig.order : ''}`
        );
        if (response.status === true) {
            const valuePackages = response.data.payments.data;
            const SerialNo = (page - 1) * size + 1;
            const newCustomerList = Object.keys(valuePackages).map((e, index) => {
                return {
                    Si: SerialNo + index,
                    InvoiceNo: valuePackages[e].invoice_no,
                    Name: valuePackages[e].customer_name,
                    Address: valuePackages[e].billing_address,
                    Area: valuePackages[e].area_name,
                    AmountCollected: authState.currency + valuePackages[e].collected_amount,
                    PaidDate: valuePackages[e].paid_date,
                    txnDate: valuePackages[e].transaction_id,
                    id: valuePackages[e].order_id,
                    CollectedBy: valuePackages[e].collected_by,
                    customer_id: valuePackages[e].customer_id,
                    Account: valuePackages[e].account_name,
                    Sub: valuePackages[e].subscriber_id,
                };
            });
            settotalsum(response.data.total_amount);
            setCustomerList(newCustomerList);
            setTotalRows(response.data.payments.total);
        } else {
            handleApiError(data.status);
        }
        setLoading(false);
    };

    const exportCollectionExcel = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCustomerPaymentReport +
            `?export=payment_report&search=${filters?.searchKey ?? ''}&area_id=${filters?.areaFilter ?? ''}&start_date=${formatStartDate ?? ''}&end_date=${formatEndDate ?? ''}&account_id=${filters?.account_Filter ?? ''}&user_id=${filters?.userId ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}` :
            getCustomerPaymentReportRole + `?export=payment_report&search=${filters?.searchKey ?? ''}&area_id=${filters?.areaFilter ?? ''}&start_date=${formatStartDate ?? ''}&end_date=${formatEndDate ?? ''}&account_id=${filters?.account_Filter ?? ''}&user_id=${filters?.userId ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.payment.map(({ subscriber_id, customer_name, customer_address, area_name, transaction_id, date, amount, invoice_number, payment_type }, index) => ({ Sl: index + 1, Subscriber_id: subscriber_id, Name: customer_name, Address: customer_address, Area: area_name, Transaction_id: transaction_id, Date: date, Amount: amount, Invoice_number: invoice_number, Payment_type: payment_type }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'payment Data');
            XLSX.writeFile(wb, 'payment_report.xlsx');
        } else {
            handleApiError(data.status);
        }
        setLoading(false);
    };

    const exportCollectionPdf = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCustomerPaymentReport +
            `?export=payment_report&search=${filters?.searchKey ?? ''}&area_id=${filters?.areaFilter ?? ''}&start_date=${formatStartDate ?? ''}&end_date=${formatEndDate ?? ''}&account_id=${filters?.account_Filter ?? ''}&user_id=${filters?.userId ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}` :
            getCustomerPaymentReportRole + `?export=payment_report&search=${filters?.searchKey ?? ''}&area_id=${filters?.areaFilter ?? ''}&start_date=${formatStartDate ?? ''}&end_date=${formatEndDate ?? ''}&account_id=${filters?.account_Filter ?? ''}&user_id=${filters?.userId ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.payment.map(({ subscriber_id, customer_name, customer_address, area_name, transaction_id, date, amount, invoice_number, payment_type }, index) => ({ Sl: index + 1, Subscriber_id: subscriber_id, Name: customer_name, Address: customer_address, Area: area_name, Transaction_id: transaction_id, Date: date, Amount: amount, Invoice_number: invoice_number, Payment_type: payment_type }));
        if (response.status === true) {
            const doc = new jsPDF();
            const columns = ["Sl", "Subscriber id", "Name", "Address", "Area", "Transaction id", "Date", "Amount", "invoice number", "Payment Type"];
            const rows = dataToExport.map(({ Subscriber_id, Name, Address, Area, Transaction_id, Date, Amount, Invoice_number, Payment_type }, index) => [index + 1, Subscriber_id, Name, Address, Area, Transaction_id, Date, Amount, Invoice_number, Payment_type]);
            doc.autoTable({
                head: [columns],
                body: rows
            });
            doc.save("collection_data.pdf");
        } else {
            handleApiError(data.status);
        }
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchCustomerPayment(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchCustomerPayment(page, newPerPage);
        setPerPage(newPerPage);
    };

    const accountData = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getAccount : getAccountRole);
        if (data.status === true) {
            setapiAccount(data.data);
        } else {
            handleApiError(data.status);
        }
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={PaymentCollectionRep} parent="Reports" title={PaymentCollectionRep} />
            <Progress details={StaticWidgetsData} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{PaymentCollectionList}</H3>
                    <div>
                        {(authState.userRole === 1 || authState.permissions.includes('export-report')) &&
                            <CardBody className="dropdown-basic">
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                        {Export} <i className="icofont icofont-arrow-up"></i>
                                    </DropdownToggle>
                                    <DropdownMenu container="body">
                                        <DropdownItem style={toggleStyle} onClick={() => exportCollectionExcel()} ><i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                                        <DropdownItem style={toggleStyle} onClick={() => exportCollectionPdf()}><i className="fa fa-file-pdf-o me-2"></i> Pdf</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </CardBody>
                        }
                    </div>
                </CardHeader>

                <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Row className="ps-3 gap-sm-3 gap-md-2 filterGap">
                        <Col sm="12" md='5' lg='2'>
                            <input className="form-control" placeholder={Search} type="text"
                                {...register('search')} />
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element" >
                            <div style={{ position: 'relative', zIndex: 2 }}>
                                <DatePicker
                                    className="form-control digits w-100"
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    isClearable={true}
                                    monthsShown={2}
                                    popperPlacement="bottom"
                                    calendarClassName='parallel-calendar'
                                    placeholderText='Select Date Range'
                                />
                            </div>
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <AreaDropdown
                                authState={authState}
                                registerName="area"
                                selectedAreaId={filters.areaFilter}
                                register={register}
                                setValue={setValue}
                            />
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <select className="form-select" {...register('account')}>
                                <option value="">{Account}</option>
                                {apiAccount.map((values, index) => (
                                    <option key={index} value={values.id}>{values.name}</option>
                                ))}
                            </select>
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <UserDropdown
                                authState={authState}
                                registerName="user"
                                selectedUserId={filters.userId}
                                register={register}
                                setValue={setValue}
                            />
                        </Col>
                        <Col sm="12" md="5" lg='1' className="custom-filtrs">
                            <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                        </Col>
                    </Row>
                </Form>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={PaymentCollectionColumns}
                            data={customerLists}
                            onSort={handleSort} // Attach sorting handler here
                            sortServer // Enable server-side sorting
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default CustomerPaymentReport;