import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardBody, Col, Form, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Breadcrumbs, Btn, H4 } from '../../AbstractElements';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DatePicker from 'react-datepicker';
import { getClientUrlDataToken } from '../../Utils/restUtils';
import { UnpaidCustomerRep, UnpaidCustomers, Export, Search, Filter } from '../../Constant';
import { getUnpaidCustomerReport, getUnpaidCustomerReportRole } from '../../CommonUrl';
import { useSelector } from 'react-redux';
import Transparentspinner from '../Common/Loader/Transparentspinner'
import moment from 'moment';
import { useForm } from 'react-hook-form';
import AreaDropdown from '../Common Field/AreaDropdown';


const UnpaidCustomer = () => {
    const authState = useSelector((state) => state.auth);
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const sortedBy = params.get("sortBy") || '';
    const sortedOrder = params.get("sortOrder") || '';
    const [filters, setFilters] = useState({
        search: params.get('search') || '',
        area: params.get('area') || '',
        start_date: params.get('start_date') ? moment(params.get('start_date'), "DD-MM-YYYY").toDate() : '',
        end_date: params.get('end_date') ? moment(params.get('end_date'), "DD-MM-YYYY").toDate() : ''
    });
    const { register, handleSubmit, setValue } = useForm({
        defaultValues: { search: filters.search, area: filters.area },
    });
    const [customerLists, setCustomerList] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [dateRange, setDateRange] = useState([filters.start_date, filters.end_date]);
    const [startDate, endDate] = dateRange
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const [sortConfig, setSortConfig] = useState({ column: sortedBy, order: sortedOrder });
    const formatStartDate = filters.start_date ? moment(filters.start_date).format("DD-MM-YYYY") : '';
    const formatEndDate = filters.end_date ? moment(filters.end_date).format("DD-MM-YYYY") : '';

    const UnpaidCustomerColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            center: false,
            width: '80px'
        },
        {
            name: <H4>Subscriber Id</H4>,
            id: 'subscriber_id',
            selector: row =>
                (authState.userRole === 1 || authState.permissions.includes('show-customer-profile')) ?
                    <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.customer_id}`}>
                        {row.Sub}
                    </Link> : row.Sub,
            sortable: true,
            wrap: true,
            width: '145px',
        },
        {
            name: <H4>Name</H4>,
            id: 'customer_name',
            selector: row => row.Name,
            sortable: true,
            center: false,
            wrap: true,
            width: '170px',
        },
        {
            name: <H4>Address</H4>,
            id: 'customer_address',
            selector: row => row.Address,
            sortable: true,
            wrap: true,
            width: '280px',
        },
        {
            name: <H4>Area</H4>,
            id: 'area_name',
            selector: row => row.Area,
            sortable: true,
            wrap: true,
            width: '200px',
        },
        {
            name: <H4>Dues</H4>,
            id: 'dues',
            selector: row => row.Dues,
            sortable: true,
            wrap: true,
            width: '140px',
        },
        {
            name: <H4>Last Payment Date</H4>,
            id: 'last_payment_date',
            selector: row => row.paymentDate,
            sortable: true,
            center: true,
        },
    ];

    useEffect(() => {
        fetchUnpaidCustomer(1);
    }, []);

    useEffect(() => {
        setValue('search', filters.search);
    }, [setValue, filters.search]);

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleSort = (column) => {
        let newOrder = 'asc';
        if (sortedBy === column.id) {
            newOrder = sortedOrder === 'asc' ? 'desc' : 'asc';
            setSortConfig({ column: column.id, order: newOrder });
            navigate(`/report/unpaid-customer?search=${filters.search}&start_date=${formatStartDate}&end_date=${formatEndDate}&area=${filters.area}&sortBy=${column.id}&sortOrder=${newOrder}`);
        } else {
            newOrder = sortedOrder === 'asc' ? 'desc' : 'asc';
            setSortConfig({ column: column.id, order: newOrder });
            navigate(`/report/unpaid-customer?search=${filters.search}&start_date=${formatStartDate}&end_date=${formatEndDate}&area=${filters.area}&sortBy=${column.id}&sortOrder=${newOrder}`);
        }
    };

    const handleApiError = (status) => {
        if (status >= 400 && status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (status >= 500 && status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const onSubmit = async (data) => {
        const searchKey = data.search || '';
        const start_date = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end_date = endDate ? moment(endDate).format("DD-MM-YYYY") : '';
        const areaFilter = data.area || '';
        const queryParams = new URLSearchParams({
            search: searchKey,
            start_date,
            end_date,
            area: areaFilter,
            sortBy: sortConfig.column || '',
            sortOrder: sortConfig.order || '',
        }).toString();
        navigate(`/report/unpaid-customer?${queryParams}`);
        setFilters({
            searchKey,
            start_date,
            end_date,
            areaFilter,
        });
        fetchUnpaidCustomer(currentPage, perPage, searchKey, start_date, end_date, areaFilter);
    };


    const fetchUnpaidCustomer = async (page, size = perPage) => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getUnpaidCustomerReport +
            `?page=${page}&page_length=${size}&search=${filters.search}&start_date=${formatStartDate}&end_date=${formatEndDate}
            &area_id=${filters.area}&sort_by=${sortConfig.column ? sortConfig.column : ''}&sort_order=${sortConfig.order ? sortConfig.order : ''}` :
            getUnpaidCustomerReportRole + `?page=${page}&page_length=${size}&search=${filters.search}&start_date=${formatStartDate}
            &end_date=${formatEndDate} &area_id=${filters.area}&sort_by=${sortConfig.column ? sortConfig.column : ''}
            &sort_order=${sortConfig.order ? sortConfig.order : ''}`);
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            const valuePackages = response.data.data;
            const SerialNo = (page - 1) * size + 1;
            setCustomerList(Object.keys(valuePackages).map((e, index) => {
                return {
                    Si: SerialNo + index,
                    Sub: valuePackages[e].subscriber_id,
                    Name: valuePackages[e].customer_name,
                    Address: valuePackages[e].customer_address,
                    Area: valuePackages[e].area_name,
                    Dues: authState.currency + " " + valuePackages[e].dues,
                    paymentDate: valuePackages[e].last_payment_date,
                    customer_id: valuePackages[e].customer_id,
                }
            }
            ))
            setTotalRows(response.data.total);
        } else {
            handleApiError(data.status);
        }
        setLoading(false)
    };

    const exportCollectionExcel = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getUnpaidCustomerReport +
            `?export=upaid_customers&search=${filters?.search ?? ''}&area_id=${filters?.area ?? ''}
            &start_date=${formatStartDate ?? ''}&end_date=${formatEndDate ?? ''}&sort_by=${sortConfig.column}
            &sort_order=${sortConfig.order}` :
            getUnpaidCustomerReportRole + `?export=upaid_customers&search=${filters?.search ?? ''}
            &area_id=${filters?.area ?? ''}&start_date=${formatStartDate ?? ''}&end_date=${formatEndDate ?? ''}
            &sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.data.map(({ subscriber_id, customer_name, customer_address, area_name, dues, last_payment_date }, index) => ({ Sl: index + 1, Subscriber_id: subscriber_id, Name: customer_name, Address: customer_address, Area: area_name, Dues: authState.currency + dues, Payment_date: last_payment_date }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Unpaid customers Data');
            XLSX.writeFile(wb, 'unpaid_customers_data.xlsx');
        } else {
            handleApiError(data.status);
        }
        setLoading(false);
    };

    const exportCollectionPdf = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getUnpaidCustomerReport +
            `?export=upaid_customers&search=${filters?.search ?? ''}&area_id=${filters?.area ?? ''}
            &start_date=${formatStartDate ?? ''}&end_date=${formatEndDate ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}` :
            getUnpaidCustomerReport + `?export=upaid_customers&search=${filters?.search ?? ''}&area_id=${filters?.area ?? ''}
            &start_date=${formatStartDate ?? ''}&end_date=${formatEndDate ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.data.map(({ subscriber_id, customer_name, customer_address, area_name, dues, last_payment_date }, index) => ({ Sl: index + 1, Subscriber_id: subscriber_id, Name: customer_name, Address: customer_address, Area: area_name, Dues: dues, Last_payment_date: last_payment_date }));
        if (response.status === true) {
            const doc = new jsPDF();
            const columns = ["Sl", "Subscriber id", "Name", "Address", "Area", "Dues", "Last Payment Date"];
            const rows = dataToExport.map(({ Subscriber_id, Name, Address, Area, Dues, Last_payment_date }, index) => [index + 1, Subscriber_id, Name, Address, Area, Dues, Last_payment_date]);
            doc.autoTable({
                head: [columns],
                body: rows
            });
            doc.save("unpaid_customers_data.pdf");
        } else {
            handleApiError(data.status);
        }
        setLoading(false);
    };

    const handlePageChange = page => {
        fetchUnpaidCustomer(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchUnpaidCustomer(page, newPerPage);
        setPerPage(newPerPage);
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={UnpaidCustomers} parent="Reports" title={UnpaidCustomers} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{UnpaidCustomerRep}</H3>
                    <div>
                        {(authState.userRole === 1 || authState.permissions.includes('export-report')) &&
                            <CardBody className="dropdown-basic">
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                        {Export} <i className="icofont icofont-arrow-up"></i>
                                    </DropdownToggle>
                                    <DropdownMenu container="body">
                                        <DropdownItem style={toggleStyle} onClick={() => exportCollectionExcel()} ><i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                                        <DropdownItem style={toggleStyle} onClick={() => exportCollectionPdf()}><i className="fa fa-file-pdf-o me-2"></i> Pdf</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </CardBody>
                        }
                    </div>
                </CardHeader>

                <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Row className="ps-3 gap-sm-3 gap-md-2 filterGap">
                        <Col sm="12" md='5' lg='2' className="form-element">
                            <input className="form-control" placeholder={Search} type="text"
                                {...register('search')} />
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <div style={{ position: 'relative', zIndex: 2 }}>
                                <DatePicker
                                    className="form-control digits w-100"
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    isClearable={true}
                                    monthsShown={2}
                                    popperPlacement="bottom"
                                    calendarClassName='parallel-calendar'
                                    placeholderText='Select Date Range'

                                />
                            </div>
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <AreaDropdown
                                authState={authState}
                                registerName="area"
                                selectedAreaId={filters.area}
                                register={register}
                                setValue={setValue}
                            />
                        </Col>
                        <Col sm="12" md="5" lg='1' className="custom-filtrs">
                            <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                        </Col>
                    </Row>
                </Form>
                <Fragment>
                </Fragment>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={UnpaidCustomerColumns}
                            data={customerLists}
                            onSort={handleSort} // Attach sorting handler here
                            sortServer // Enable server-side sorting
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default UnpaidCustomer;