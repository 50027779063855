import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Col, Row, } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Breadcrumbs, Btn, H4 } from '../../AbstractElements';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken } from '../../Utils/restUtils';
import { Add, WalletBalanceRep, TotalWalletMoney, Export, Filter, Search, Account, Customer } from '../../Constant';
import { useForm } from 'react-hook-form';
import { viewWalletReport, viewWalletReportRole, getAccount, getCustomers, getAccountRole, getCustomersRole } from '../../CommonUrl';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import Transparentspinner from '../Common/Loader/Transparentspinner';
import HandleApiError from '../Common/ErrorImport/ErrorImport';

const WalletBalenceReport = () => {
    const authState = useSelector((state) => state.auth);
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const [filters, setFilters] = useState({
        searchKey: params.get('search') || '',
        start_date: params.get('start_date') ? moment(params.get('start_date'), "DD-MM-YYYY").toDate() : '',
        end_date: params.get('end_date') ? moment(params.get('end_date'), "DD-MM-YYYY").toDate() : '',
        customer: params.get('customer') || '',
        account: params.get('account') || '',
    });
    const sortedBy = params.get("sortBy") || '';
    const sortedOrder = params.get("sortOrder") || '';
    const [customerLists, setCustomerList] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const [apiAccount, setapiAccount] = useState([]);
    const [apiCustomer, setapiCustomer] = useState([]);
    const [dateRange, setDateRange] = useState([filters.start_date, filters.end_date]);
    const [startDate, endDate] = dateRange
    const [sortConfig, setSortConfig] = useState({ column: sortedBy, order: sortedOrder });
    const formatStartDate = filters.start_date ? moment(filters.start_date).format("DD-MM-YYYY") : '';
    const formatEndDate = filters.end_date ? moment(filters.end_date).format("DD-MM-YYYY") : '';
    const { register, handleSubmit, setValue } = useForm({
        defaultValues: { search: filters.searchKey, customer: filters.customer, account: filters.account },
    });

    const WalletBalenceColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            center: false,
            width: '100px'
        },
        {
            name: <H4>Subscriber Id</H4>,
            id: 'subscriber_id',
            selector: row =>
                (authState.userRole === 1 || authState.permissions.includes('show-customer-profile')) ?
                    <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.customer_id}`}>
                        {row.SubId}
                    </Link> : row.SubId,
            sortable: true,
            wrap: true,
            center: false,
        },
        {
            name: <H4>Customer</H4>,
            id: 'customer_name',
            selector: row => row.Customer,
            sortable: true,
            center: false,
            wrap: true
        },
        {
            name: <H4>Txn Id</H4>,
            id: 'transaction_id',
            selector: row => row.TxnId,
            sortable: true,
            center: false,
            wrap: true
        },
    
        {
            name: <H4>Date</H4>,
            id: 'date',
            selector: row => row.Date,
            sortable: true,
            center: false,
            wrap: true
        },
        {
            name: <H4>Amount</H4>,
            id: 'amount',
            selector: row => row.Amount,
            sortable: true,
            center: false,
        },
    ];

    useEffect(() => {
        fetchWalletRep(1);
        accountData()
        customerData()
    }, []);

    useEffect(() => {
        setValue('search', filters.searchKey);
    }, [setValue, filters.search]);

    useEffect(() => {
        if (filters.account) {
            const selectedAccount = apiAccount.find((account) => account.id === parseInt(filters.account));
            if (selectedAccount) {
                setValue('account', selectedAccount.id.toString());
            }
        }
    }, [setValue, filters.account, apiAccount]);

    useEffect(() => {
        if (filters.customer) {
            const selectedAccount = apiCustomer.find((account) => account.id === parseInt(filters.customer));
            if (selectedAccount) {
                setValue('customer', selectedAccount.id.toString());
            }
        }
    }, [setValue, filters.customer, apiCustomer]);

 const handleSort = (column) => {
        let newOrder = 'asc';
        if (sortedBy === column.id) {
            newOrder = sortedOrder === 'asc' ? 'desc' : 'asc';
            setSortConfig({ column: column.id, order: newOrder });
            navigate(`/report/wallet-balance?search=${filters.searchKey}&start_date=${filters.start_date}&end_date=${filters.end_date}&account=${filters.account}&customer=${filters.customer}&sortBy=${column.id}&sortOrder=${newOrder}`);
        } else {
            newOrder = sortedOrder === 'asc' ? 'desc' : 'asc';
            setSortConfig({ column: column.id, order: newOrder });
            navigate(`/report/wallet-balance?search=${filters.searchKey}&start_date=${filters.start_date}&end_date=${filters.end_date}&account=${filters.account}&customer=${filters.customer}&sortBy=${column.id}&sortOrder=${newOrder}`);
        }
    };

    const handlePageChange = page => {
        fetchWalletRep(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchWalletRep(page, newPerPage);
        setPerPage(newPerPage);
    };

    const accountData = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getAccount : getAccountRole);
        if (data.status === true) {
            setapiAccount(data.data);
        } else {
            HandleApiError(navigate, response.status)
        }
    };

    const customerData = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCustomers : getCustomersRole);
        if (data.status === true) {
            setapiCustomer(data.data);
        } else {
            HandleApiError(navigate, response.status)
        }
    };

    const onSubmit = async (data) => {
        const search = data.search;
        const account = data.account;
        const customer = data.customer;
        const start_date = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end_date = endDate ? moment(endDate).format("DD-MM-YYYY") : '';
        const queryParams = new URLSearchParams({
            search: search,
            start_date,
            end_date,
            account: account,
            customer: customer,
            sortBy: sortConfig.column || '',
            sortOrder: sortConfig.order || '',
        }).toString();
        navigate(`/report/wallet-balance?${queryParams}`);
        setFilters({
            search,
            start_date,
            end_date,
            account,
            customer
        });
        fetchWalletRep(currentPage, perPage, search, start_date, end_date, account, customer);
    };

    const fetchWalletRep = async (page, size = perPage) => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewWalletReport +
            `?page=${page}&page_length=${size}&search=${filters.searchKey}&start_date=${filters.start_date}
            &end_date=${filters.end_date}&account_id=${filters.account}&customer_id=${filters.customer}
            &sort_by=${sortConfig.column ? sortConfig.column : ''}&sort_order=${sortConfig.order ? sortConfig.order : ''}` :
            viewWalletReportRole + `?page=${page}&page_length=${size}&search=${filters.searchKey}&start_date=${filters.start_date}
            &end_date=${filters.end_date}&account_id=${filters.account}&customer_id=${filters.customer}
            &sort_by=${sortConfig.column ? sortConfig.column : ''}&sort_order=${sortConfig.order ? sortConfig.order : ''}`);
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            const valueWallets = response.data.wallet.data;
            const SerialNo = (page - 1) * size + 1;
            setCustomerList(Object.keys(valueWallets).map((e, index) => {
                return {
                    Si: SerialNo + index,
                    TxnId: valueWallets[e].transaction_id,
                    Date: valueWallets[e].date,
                    Amount: <span style={{ fontWeight: 'bold', color: '#534686' }}>{authState.currency}{valueWallets[e].amount}</span>,
                    SubId: valueWallets[e].subscriber_id,
                    Customer: valueWallets[e].customer_name,
                    customer_id: valueWallets[e].customer_id,
                }
            }
            ))
            setTotalRows(response.data.wallet.total);
        } else {
            HandleApiError(navigate, response.status)
        }
        setLoading(false);
    };

    const exportWalletBalanceExcel = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewWalletReport +
            `?export=wallet_balance_report&search=${filters?.searchKey ? filters.searchKey : ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}&account_id=${filters?.account ?? ''}&customer_id=${filters?.customer ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}` :
            viewWalletReport + `?export=wallet_balance_report&search=${filters?.searchKey ? filters.searchKey : ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}&account_id=${filters?.account ?? ''}&customer_id=${filters?.customer ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.data.wallet.map(({ transaction_id, date, amount, subscriber_id, customer_name }) => ({ Transaction_id: transaction_id, Date: date, Amount: authState.currency + amount, Subscriber_Id: subscriber_id, Customer: customer_name }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Wallet Balance report');
            XLSX.writeFile(wb, 'wallet_balance_report.xlsx');
        } else {
            HandleApiError(navigate, response.status)
        }
        setLoading(false);
    };
    const exportWalletBalancePdf = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewWalletReport +
            `?export=wallet_balance_report&search=${filters?.searchKey ? filters.searchKey : ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}&account_id=${filters?.account ?? ''}&customer_id=${filters?.customer ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}` :
            viewWalletReport + `?export=wallet_balance_report&search=${filters?.searchKey ? filters.searchKey : ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}&account_id=${filters?.account ?? ''}&customer_id=${filters?.customer ?? ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.data.wallet.map(({ transaction_id, date, amount, subscriber_id, customer_name }, index) => ({ Sl: index + 1, transaction_id, date, amount, subscriber_id, customer_name }));
        if (response.status === true) {
            const doc = new jsPDF();
            const columns = ["Sl", "Transaction id", "Date", "Amount", "Subscriber Id", "Customer"];
            const rows = dataToExport.map(({ transaction_id, date, amount, subscriber_id, customer_name }, index) => [index + 1, transaction_id, date, amount, subscriber_id, customer_name]);
            doc.autoTable({
                head: [columns],
                body: rows
            });
            doc.save("wallet_report_data.pdf");
        } else {
            HandleApiError(navigate, response.status)
        }
        setLoading(false);
    };


    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={WalletBalanceRep} parent="Reports" title={WalletBalanceRep} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{TotalWalletMoney}</H3>
                    <div>
                        {(authState.userRole === 1 || authState.permissions.includes('export-report')) &&
                            <CardBody className="dropdown-basic">
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                        {Export} <i className="icofont icofont-arrow-up"></i>
                                    </DropdownToggle>
                                    <DropdownMenu container="body">
                                        <DropdownItem style={toggleStyle} onClick={() => exportWalletBalanceExcel()} ><i className="fa fa-file-excel-o me-2"></i>Excel</DropdownItem>
                                        <DropdownItem style={toggleStyle} onClick={() => exportWalletBalancePdf()}><i className="fa fa-file-pdf-o me-2"></i>Pdf</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </CardBody>
                        }
                    </div>
                </CardHeader>
                <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Row className="ps-3 gap-sm-3 gap-md-2 filterGap">
                        <Col sm="12" md='5' lg='2'>
                            <input className="form-control" placeholder={Search} type="text"
                                {...register('search')} />
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element" >
                            <div style={{ position: 'relative', zIndex: 2 }}>
                                <DatePicker
                                    className="form-control digits w-100"
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    isClearable={true}
                                    monthsShown={2}
                                    popperPlacement="bottom"
                                    calendarClassName='parallel-calendar'
                                    placeholderText='Select Date Range'

                                />
                            </div>
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <select className="form-select"  {...register('account')}>
                                <option value="">{Account}</option>
                                {apiAccount.map((values, index) => (
                                    <option key={index} value={values.id}>{values.name}</option>
                                ))}
                            </select>
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <select className="form-select"  {...register('customer')}>
                                <option value="">{Customer}</option>
                                {apiCustomer.map((values, index) => (

                                    <option key={index} value={values.id}>{values.name}</option>
                                ))}
                            </select>
                        </Col>
                        <Col sm="12" md="5" lg='1' className="custom-filtrs">
                            <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                        </Col>
                    </Row>
                </Form>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={WalletBalenceColumns}
                            data={customerLists}
                            onSort={handleSort} // Attach sorting handler here
                            sortServer // Enable server-side sorting
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationRowsPerPageOptions={[ 10, 25, 50, 100]}
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default WalletBalenceReport;